import React, {Fragment, useEffect} from "react";
import PropTypes from "prop-types";
import Helper from "../../../helpers/helper";
import {customStyles} from "../../../helpers/select";
import {find as _find, concat as _concat, uniqBy as _uniqBy, get as _get} from "lodash";
import Select from "react-select";
import useGroupEmailUsersQuery from "../../../hooks/api/Alert/useGroupEmailUsersQuery";

const EmailTemplate = ({alert, users, level, update}) => {
    const locationId = _get(alert, "installationPoint.equipment.location_id");
    const {groupEmailUserList = [], isSuccess} = useGroupEmailUsersQuery({
        alertLevelId: +level.id,
        equipmentId: _get(alert, ["installationPoint", "equipment_id"]),
        instPointId: _get(alert, ["installationPointId"]),
        locationId: locationId,
        chartType: _get(alert, "chartType"),
    });

    let preCheckedUsers = [];
    (users || []).forEach((user) => {
        if ((user.alert_levels || []).indexOf(+level.id) !== -1 && (_get(user, "locationsTree", []).indexOf(locationId) !== -1 || user.all_facilities)) {
            preCheckedUsers.push({value: user.id, label: user.full_name, isFixed: true, title: "User is signed up for this alert level."});
        }
    });

    let emailUsers = [];
    (alert.emailUsers || []).forEach((userId) => {
        const user = _find(users, (user) => +user.id === +userId);
        if (user) {
            emailUsers.push({value: userId, label: (user || {}).full_name, isFixed: false});
        }
    });

    let groupEmailUsers = [];
    const alertGroupEmailUsers = alert.groupEmailUsers || [];
    [...groupEmailUserList, ...alertGroupEmailUsers].filter((userId) => {
        const user = _find(users, (user) => +user.id === +userId);
        if (user && !_find(preCheckedUsers, (user) => +user.value === +userId) && !_find(groupEmailUsers, (user) => +user.value === +userId)) {
            groupEmailUsers.push({
                value: userId,
                label: (user || {}).full_name,
                isFixed: true,
                title: "User is included in Alert Group for this equipment.",
            });
        }
    });

    let values = _uniqBy(_concat(preCheckedUsers, groupEmailUsers, emailUsers), "value");
    // const emailNotifications = Boolean(preCheckedUsers.length || groupEmailUserList.length || alert.email === "1");
    const emailNotifications = alert.email === "1";

    useEffect(() => {
        const isChecked = Boolean(preCheckedUsers.length || groupEmailUserList.length || alert.email === "1");

        update({email: (+isChecked).toString()});
    }, [isSuccess, users]);

    return (
        <Fragment>
            <div className="row mt-2 mt-md-0">
                <div className="col-md-12">
                    <label className="form-checkbox form-checkbox--alerts">
                        <input
                            type="checkbox"
                            checked={emailNotifications}
                            onChange={(ev) => update({email: preCheckedUsers.length || groupEmailUserList.length ? "1" : Helper.getInputValue(ev.target)})}
                        />{" "}
                        Send Email Notifications
                        <span />
                    </label>
                </div>
            </div>
            {emailNotifications && (
                <div className="row row-email-subscriber">
                    <div className="col-md-12">
                        <Select
                            className="form-control-select"
                            isMulti
                            isLoading={!isSuccess}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            styles={{
                                ...customStyles,
                                control: (base) => ({
                                    ...base,
                                    minHeight: 30,
                                    ...(localStorage.getItem("lightMode") === "true" ? {backgroundColor: "rgb(56, 56, 46)"} : {}),
                                }),
                                multiValue: (base, state) => (state.data.isFixed ? {...base, backgroundColor: "gray"} : base),
                                multiValueLabel: (base, state) =>
                                    state.data.isFixed ? {...base, fontWeight: "bold", color: "white", paddingRight: 6} : base,
                                multiValueRemove: (base, state) => (state.data.isFixed ? {...base, display: "none"} : base),
                            }}
                            value={values}
                            options={(users || []).map((user) => ({
                                value: user.id,
                                label: user.full_name,
                                isFixed: (user.alert_levels || []).indexOf(+level.id) !== -1,
                            }))}
                            onChange={(values) => {
                                let emailUsers = [];
                                (values || []).forEach((item) => {
                                    if (!item.isFixed) emailUsers.push(item.value);
                                });
                                update({emailUsers});
                            }}
                            formatOptionLabel={formatOptionLabel}
                        />
                    </div>
                </div>
            )}
        </Fragment>
    );
};

const formatOptionLabel = ({label, title}) => (
    <div
        style={{display: "flex"}}
        title={title || ""}
    >
        <div>{label}</div>
    </div>
);

EmailTemplate.propTypes = {
    isCurrent: PropTypes.bool,
    users: PropTypes.array,
    alert: PropTypes.object,
    level: PropTypes.object,
    update: PropTypes.func,
};

export default EmailTemplate;
